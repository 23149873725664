import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../models/User';
import { OfflineError } from './base.service';
import { StorageService } from './storage.service';

const AUTH_USER = 'auth-user';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(null);

  constructor(
    protected storage: StorageService,
    protected platform: Platform,
    protected http: HttpClient
  ) {}

  async getCachedUser() {
    const user = await this.storage.get(AUTH_USER);
    return user;
  }

  async logout(): Promise<void> {
    await this.storage.remove(AUTH_USER);
  }

  getOfflineError(): Observable<Error> {
    return throwError(
      () =>
        new OfflineError(
          'You are currently offline! Please check your Internet connection.'
        )
    );
  }

  login(loginData: any): Observable<any> {
    if (!navigator.onLine) {
      return this.getOfflineError();
    }
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Skip-Auth': 'true',
    });
    return this.http
      .post(`${environment.API_URL}staff/login`, loginData, { headers })
      .pipe(
        mergeMap((response: any) => {
          const user = User.parseFromData(response.user) || null;
          return from(this.storage.set(AUTH_USER, response.user)).pipe(
            mergeMap(() => of(user))
          );
        }),
        catchError(error => {
          console.error(error);
          Sentry.addBreadcrumb({
            category: 'auth',
            message: 'Authentication failed',
            level: Sentry.Severity.Info,
            data: error,
          });

          if (!this.shouldIgnoreError(error)) {
            Sentry.captureEvent({ ...error, extra: error });
          }
          return throwError(
            () => 'Cannot login. Make sure all login details are correct.'
          );
        })
      );
  }

  shouldIgnoreError(error: any): boolean {
    return (
      error.status === 504 ||
      error.status === 401 ||
      (error.status === 0 && error.error instanceof ProgressEvent)
    );
  }

  refreshUserSettings(existingToken: string): Observable<any> {
    if (!navigator.onLine) {
      return this.getOfflineError();
    }
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    });
    return this.http
      .post(`${environment.API_URL}staff/refresh`, {}, { headers })
      .pipe(
        mergeMap((response: any) => {
          // refresh settings doesn't provide a token so we need to set an existing one
          // this is scheduled to be improved when we move API and auth to lambda
          response.user['token'] = existingToken;
          const user = User.parseFromData(response.user) || null;
          return from(this.storage.set(AUTH_USER, response.user)).pipe(
            mergeMap(() => of(user))
          );
        }),
        catchError(error => {
          console.error(error);
          if (!this.shouldIgnoreError(error)) {
            Sentry.captureEvent({ ...error, extra: error });
          }
          return throwError(
            () => 'Cannot login. Make sure all login details are correct.'
          );
        })
      );
  }

  requestPasswordRecovery(requestData: any): Observable<any> {
    if (!navigator.onLine) {
      return this.getOfflineError();
    }
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Skip-Auth': 'true',
    });
    return this.http
      .post(`${environment.API_URL}auth/forgot/maintenance`, requestData, {
        headers,
      })
      .pipe(
        catchError(error => {
          Sentry.captureEvent({ ...error, extra: error });
          return throwError(
            () =>
              'Cannot request new password. Make sure all details are correct.'
          );
        })
      );
  }

  resetRecovery(requestData: any): Observable<any> {
    if (!navigator.onLine) {
      return this.getOfflineError();
    }
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Skip-Auth': 'true',
    });
    return this.http
      .post(`${environment.API_URL}auth/reset`, requestData, { headers })
      .pipe(
        catchError(error => {
          Sentry.captureEvent({ ...error, extra: error });
          return throwError(
            () => 'Cannot reset password. Make sure all details are correct.'
          );
        })
      );
  }
}
