import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { User } from '../models/User';
import { AuthenticationService } from './authentication.service';
import { BaseStore } from './base.store';

@Injectable({ providedIn: 'root' })
export class UserStore extends BaseStore {
  private readonly _user = new BehaviorSubject<User>(null);

  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly user$ = this._user.asObservable();

  constructor(protected authenticationService: AuthenticationService) {
    super();
  }

  get user(): User {
    return this._user.getValue();
  }

  set user(val: User) {
    this._user.next(val);
  }

  async init(): Promise<void> {
    if (this.user) {
      return;
    }

    const user = await this.authenticationService.getCachedUser();
    if (user) {
      this.user = User.parseFromData(user);
    }
  }

  login(data: any) {
    return this.authenticationService.login(data).pipe(
      mergeMap(user => {
        this.user = user;
        return of(user);
      })
    );
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();
    this.user = null;
  }

  isAuthenticated(): boolean {
    return this.user !== null;
  }

  refreshSettings() {
    return this.authenticationService.refreshUserSettings(this.user.token).pipe(
      mergeMap(user => {
        this.user = user;
        return of(user);
      })
    );
  }
}
