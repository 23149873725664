/* eslint-disable @typescript-eslint/naming-convention */
export default class Permissions {
  public viewInHouse = false;
  public viewAvailability = false;
  public viewJobs = false;
  public viewVacant = false;
  public sendMaintenanceReport = false;
  public sendMessage = false;
  public invoice = false;
  public contacts = false;
  public bookings = false;

  constructor(data: {
    portal_inhouse: boolean;
    portal_jobs: boolean;
    portal_vacant: boolean;
    portal_maintenance: boolean;
    portal_message: boolean;
    portal_invoice: boolean;
    portal_availability: boolean;
    portal_contacts: boolean;
    portal_bookings: boolean;
  }) {
    this.viewInHouse = !!data.portal_inhouse;
    this.viewAvailability = !!data.portal_availability;
    this.viewJobs = !!data.portal_jobs;
    this.viewVacant = !!data.portal_vacant; //legacy name
    this.sendMaintenanceReport = !!data.portal_maintenance;
    this.sendMessage = !!data.portal_message;
    this.invoice = !!data.portal_invoice;
    this.contacts = !!data.portal_contacts;
    this.bookings = !!data.portal_bookings;
  }

  canViewGuests(): boolean {
    return this.viewInHouse;
  }

  canViewAvailability(): boolean {
    return this.viewAvailability;
  }

  canViewJobs(): boolean {
    return this.viewJobs;
  }

  canViewProperties(): boolean {
    return this.viewVacant;
  }

  canSendMaintenanceReport(): boolean {
    return this.sendMaintenanceReport;
  }

  canSendMessage(): boolean {
    return this.sendMessage;
  }

  canSeeInvoices(): boolean {
    return this.invoice;
  }

  canViewContacts(): boolean {
    return this.contacts;
  }

  canMakeBookings(): boolean {
    return this.bookings;
  }
}
