<ion-app>
  <ion-split-pane [disabled]="!showMenu" contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-header>
        <ion-toolbar color="primary"> <ion-title>Menu</ion-title> </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list class="app-menu">
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; trackBy: trackByPage">
            <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" [queryParams]="p.params">
              <ion-icon
                slot="start"
                [name]="p.icon"
                class="menu-icon"
              ></ion-icon>
              <ion-label class="menu-text"> {{ p.title }} </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="refresh()">
              <ion-icon
                slot="start"
                name="refresh"
                class="menu-icon"
              ></ion-icon>
              <ion-label class="menu-text"> Get latest updates </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item (click)="logout()">
              <ion-icon
                slot="start"
                name="log-out"
                class="menu-icon"
              ></ion-icon>
              <ion-label class="menu-text"> Logout </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <div class="ion-padding">
            Powered by
            <a
              href="https://homhero.com.au"
              title="HOMHERO"
              target="blank"
              class="menu-text"
              >HOMHERO</a
            >
            v{{ version }}
          </div>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <div [@buttonState] *ngIf="!isOnline" class="app-offline-footer">You are offline!</div>
</ion-app>
