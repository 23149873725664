import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export class OfflineError implements Error {
  readonly name = 'OfflineError';
  readonly message: string;
  readonly error: any | null;

  constructor(message: string) {
    this.message = message;
  }
}

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  constructor(protected http: HttpClient) {}

  shouldIgnoreError(error: any): boolean {
    return (
      error.status === 504 ||
      (error.status === 0 && error.error instanceof ProgressEvent)
    );
  }

  callGet(url: string): Observable<any> {
    if (!navigator.onLine) {
      return this.getOfflineError();
    }
    return this.http.get(`${environment.API_URL}${url}`).pipe(
      catchError(error => {
        console.error(error);
        if (!this.shouldIgnoreError(error)) {
          Sentry.captureEvent({ ...error, extra: error });
        }
        return throwError(() => error);
      })
    );
  }

  callGetIgnoreLoader(url: string): Observable<any> {
    if (!navigator.onLine) {
      return this.getOfflineError();
    }
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Skip-Loader': 'true',
    });
    return this.http.get(`${environment.API_URL}${url}`, { headers }).pipe(
      catchError(error => {
        console.error(error);
        if (!this.shouldIgnoreError(error)) {
          Sentry.captureEvent({ ...error, extra: error });
        }
        return throwError(() => error);
      })
    );
  }

  callPost(url: string, data: any, options?: any): Observable<any> {
    if (!navigator.onLine) {
      return this.getOfflineError();
    }
    return this.http.post(`${environment.API_URL}${url}`, data, options).pipe(
      catchError(error => {
        console.error(error);
        if (!this.shouldIgnoreError(error)) {
          Sentry.captureEvent({ ...error, extra: error });
        }
        return throwError(() => error);
      })
    );
  }

  callPut(url: string, data: any): Observable<any> {
    if (!navigator.onLine) {
      return this.getOfflineError();
    }
    return this.http.put(`${environment.API_URL}${url}`, data).pipe(
      catchError(error => {
        console.error(error);
        if (!this.shouldIgnoreError(error)) {
          Sentry.captureEvent({ ...error, extra: error });
        }
        return throwError(() => error);
      })
    );
  }

  getOfflineError(): Observable<Error> {
    return throwError(
      () =>
        new OfflineError(
          'You are currently offline! Please check your Internet connection.'
        )
    );
  }

  getResponseMessage(
    error: Error,
    fallbackMessage: string = 'Service Error'
  ): Observable<never> {
    if (error instanceof OfflineError) {
      return throwError(() => error);
    }
    return throwError(() => new Error(fallbackMessage));
  }
}
