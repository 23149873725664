import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDateFormats,
  MAT_NATIVE_DATE_FORMATS,
} from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import * as Sentry from '@sentry/angular';
import { Event, EventHint } from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { LoadingInterceptor } from './services/loading-interceptor.service';
import { SettingsInterceptor } from './services/settings-interceptor.service';
import { StartupService } from './services/startup.service';

window['hhRelease'] = '1.2.3';

export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions,
  },
};

if (environment.production) {
  Sentry.init({
    dsn: 'https://891b1c818dae4f1ca3afc1016267acf4@sentry.io/1324096',
    environment: environment.env,
    release: window['hhRelease'],
    enabled: environment.production,
    debug: environment.env === 'staging',
    attachStacktrace: true,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      'g.readyState',
    ],
    blacklistUrls: [
      /google-analytics.com/i,
      /r.lr-ingest.io/i,
      /cdn.lr-ingest.io/i,
      // Chrome extensions
      /extensions\//i,
      /safari-extension\//i,
      /^chrome:\/\//i,
    ],
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', environment.API_URL],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    beforeSend: (
      event: Event,
      hint?: EventHint
    ): Event | PromiseLike<Event> => {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;
      if (chunkFailedMessage.test(hint?.originalException?.toString())) {
        console.log(event, hint);
        return null;
      }
      return event;
    },
  });
}

export const initConfiguration = (startupService: StartupService) => () =>
  startupService.load();

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error: any) {
    if (!environment.production) {
      console.error(error);
    }
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (!chunkFailedMessage.test(error.message)) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(chunkFailedMessage);
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    IonicStorageModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SettingsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfiguration,
      multi: true,
      deps: [StartupService, Sentry.TraceService],
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
