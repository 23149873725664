import { animate, style, transition, trigger } from '@angular/animations';
import { ApplicationRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AlertController, NavController, Platform } from '@ionic/angular';
import * as Sentry from '@sentry/browser';
import { Scope } from '@sentry/types';
import {
  concat,
  filter,
  fromEvent,
  interval,
  merge,
  of,
  Subscription,
} from 'rxjs';
import { first, map } from 'rxjs/operators';
import { globalCacheBusterNotifier } from 'ts-cacheable';
import { environment } from '../environments/environment';
import { User } from './models/User';
import { UserStore } from './services/user.store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.scss'],
  animations: [
    trigger('buttonState', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(100%)' })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnDestroy {
  public appPages = [];
  public isOnline = false;
  public showMenu = false;
  public version: string = window['hhRelease'];
  protected update$: Subscription;
  protected updateCheck$: Subscription;

  constructor(
    protected platform: Platform,
    protected router: Router,
    protected nav: NavController,
    protected alertController: AlertController,
    protected swUpdate: SwUpdate,
    protected userStore: UserStore,
    protected appRef: ApplicationRef
  ) {
    this.handleOffline();
    this.initializeApp();

    if (environment.production) {
      this.setupUpdateCheck();
      this.setupUpdate();
    }
  }

  trackByPage(index: number, page: any) {
    return page.url;
  }

  handleOffline(): void {
    merge(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      of(navigator.onLine)
    ).subscribe((isOnline: boolean) => {
      this.isOnline = isOnline;
    });
  }

  initMenu(): void {
    const { permissions } = this.userStore.user;
    this.appPages = [
      {
        title: 'Dashboard',
        url: '/',
        icon: 'home',
      },
    ];
    if (!!permissions.canViewGuests()) {
      this.appPages.push({
        title: 'In House Guests',
        url: '/bookings',
        params: { type: 'in-house' },
        icon: 'person',
      });
      this.appPages.push({
        title: 'Bookings',
        url: '/bookings',
        params: { type: 'all' },
        icon: 'person',
      });
    }

    if (!!permissions.canViewContacts()) {
      this.appPages.push({
        title: 'Contacts',
        url: '/contacts',
        icon: 'people',
      });
    }

    if (!!permissions.canViewAvailability()) {
      this.appPages.push({
        title: 'Live Availability',
        url: '/live-availability',
        icon: 'calendar',
      });
    }

    if (!!permissions.canMakeBookings()) {
      this.appPages.push({
        title: 'Quote',
        url: '/quotes',
        icon: 'today',
      });
    }

    if (!!permissions.canMakeBookings()) {
      this.appPages.push({
        title: 'Book Now',
        url: '/book-now',
        icon: 'book',
      });
    }

    if (!!permissions.canViewProperties()) {
      this.appPages.push({
        title: 'Properties',
        url: '/properties',
        icon: 'folder',
      });
    }

    if (!!permissions.canViewJobs()) {
      this.appPages.push({
        title: 'Maintenance Jobs',
        url: '/maintenance-jobs',
        icon: 'hammer',
      });
    }

    if (!!permissions.canSendMessage()) {
      this.appPages.push({
        title: 'Send Message',
        url: '/messages',
        icon: 'mail',
      });
    }

    if (!!permissions.canSendMaintenanceReport()) {
      this.appPages.push({
        title: 'Report Maintenance',
        url: '/report-maintenance',
        icon: 'clipboard',
      });
    }

    if (!!permissions.canSeeInvoices()) {
      this.appPages.push({
        title: 'Invoices',
        url: '/invoices',
        icon: 'calculator',
      });
    }
  }
  initializeApp() {
    this.userStore.user$.subscribe((user: User) => {
      if (user && user.id) {
        this.showMenu = true;
        this.initMenu();
        Sentry.configureScope((scope: Scope) => {
          // @ts-ignore
          scope.setExtras(user);
          scope.setTag('account_name', user.account.company_name);
          scope.setUser({
            id: `${user.id}`,
            email: user.email,
            username: user.username,
          });
        });
      } else {
        this.showMenu = false;
      }
    });
  }

  async logout(): Promise<any> {
    globalCacheBusterNotifier.next();
    await this.userStore.logout();
    this.nav.setDirection('root');
    this.router.navigate(['auth/login']);
  }

  async refresh(): Promise<void> {
    const confirm = await this.alertController.create({
      header: 'Update',
      message: 'Do you want to reload the app?',
      buttons: [
        {
          text: 'Cancel',
          handler: () => {},
        },
        {
          text: 'Reload',
          handler: () => {
            globalCacheBusterNotifier.next();
            this.swUpdate
              .activateUpdate()
              .then(() => document.location.reload())
              .catch((error: Error) => {
                Sentry.captureEvent({ ...error });
              });
          },
        },
      ],
    });
    await confirm.present();
  }

  setupUpdateCheck() {
    if (this.updateCheck$) {
      return;
    }
    const appIsStable$ = this.appRef.isStable.pipe(
      first((isStable: boolean) => isStable === true)
    );
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    this.updateCheck$ = everySixHoursOnceAppIsStable$.subscribe(() =>
      this.swUpdate.checkForUpdate()
    );
  }

  setupUpdate() {
    if (this.update$) {
      return;
    }
    this.update$ = this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))
      )
      .subscribe((evt: any) => {
        console.log(evt);
        this.alertController
          .create({
            header: 'Update',
            message:
              'New Update available! Reload the webapp to see the latest juicy changes.',
            buttons: [
              {
                text: 'Cancel',
                handler: () => {},
              },
              {
                text: 'Reload',
                handler: () => {
                  this.swUpdate
                    .activateUpdate()
                    .then(() => window.location.reload());
                },
              },
            ],
          })
          .then((confirm: HTMLIonAlertElement) => confirm.present())
          .catch(console.error);
      });
  }

  ngOnDestroy(): void {
    if (environment.production) {
      this.update$.unsubscribe();
      this.updateCheck$.unsubscribe();
    }
  }
}
