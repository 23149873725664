import { Injectable } from '@angular/core';
import { UserStore } from './user.store';
import { StorageService } from './storage.service';

@Injectable()
export class StartupService {
  constructor(
    protected userStore: UserStore,
    protected storageService: StorageService
  ) {}

  async load(): Promise<boolean> {
    return this.storageService.init().then(() => {
      return new Promise<boolean>((resolve, reject) => {
        if (!this.userStore.user) {
          return this.userStore
            .init()
            .then(() => resolve(true))
            .catch(() => resolve(true));
        } else {
          resolve(true);
        }
      });
    });
  }
}
