import { Account } from './Account';
import Permissions from './Permissions';

export class User {
  id: number;
  email: string;
  mobile: string;
  name: string;
  username: string;
  token: string;
  userType: string;
  account?: Account;
  permissions?: Permissions;

  constructor(data: any) {
    this.id = data.id;
    this.email = data.email;
    this.mobile = data.mobile;
    this.name = data.name;
    this.username = data.username;
    this.account = data.account;
    this.token = data.token;
    this.userType = data.userType;
    this.permissions = data.permissions;
  }

  get isHandyman(): boolean {
    return this.userType === 'handyman';
  }

  static parseFromData(data: any): User {
    const user = data.staff;
    return new User({
      id: user.id,
      email: user.email,
      mobile: user.mobile,
      name: user.name,
      username: user.username,
      account: user.account,
      token: data.token,
      userType: user.user_type,
      permissions: new Permissions(user.perms || {}),
    });
  }
}
