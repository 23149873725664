import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { UserStore } from './user.store';

@Injectable({
  providedIn: 'root',
})
export class SettingsInterceptor implements HttpInterceptor {
  public responseSettingsHash = null;

  constructor(protected router: Router, protected userStore: UserStore) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // skip hash check if we try to refresh it
    if (req.url.includes('/staff/refresh')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      mergeMap((event: HttpEvent<any>) => {
        // we only care about HttpResponse type
        if (event instanceof HttpResponse) {
          const serverHash = event.headers.get('x-homhero-sh') || null;
          // set if there is no settings hash (the first response from server)
          if (this.responseSettingsHash === null) {
            this.responseSettingsHash = serverHash;
          } else {
            if (
              this.responseSettingsHash &&
              serverHash &&
              this.responseSettingsHash !== serverHash
            ) {
              // set new hash asap so other requests will not be intercepted
              this.responseSettingsHash = serverHash;

              // refresh user setting and return the original response event
              return this.userStore.refreshSettings().pipe(
                catchError(error => {
                  // we need to catch any errors from refresh request
                  // and return the original response event
                  return of(false);
                }),
                mergeMap(() => {
                  // IMPORTANT!!!! we always return the original response event
                  return of(event);
                })
              );
            }
          }
        }
        return of(event);
      })
    );
  }
}
