import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('./pages/home/home.module').then((m: any) => m.HomePageModule),
  },
  {
    path: 'bookings',
    loadChildren: () =>
      import('./pages/bookings/bookings.module').then(
        (m: any) => m.BookingsPageModule
      ),
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./pages/contacts/contacts.module').then(
        (m: any) => m.ContactsPageModule
      ),
  },
  {
    path: 'live-availability',
    loadChildren: () =>
      import('./pages/live-availability/live-availability.module').then(
        (m: any) => m.LiveAvailabilityPageModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./public/auth/auth.module').then((m: any) => m.AuthPageModule),
  },
  {
    path: 'properties',
    loadChildren: () =>
      import('./pages/properties/properties.module').then(
        (m: any) => m.PropertiesPageModule
      ),
  },
  {
    path: 'cleans',
    loadChildren: () =>
      import('./pages/cleans/cleans.module').then(
        (m: any) => m.CleansPageModule
      ),
  },
  {
    path: 'quotes',
    loadChildren: () =>
      import('./pages/quotes/quotes.module').then(
        (m: any) => m.QuotesPageModule
      ),
  },
  {
    path: 'book-now',
    loadChildren: () =>
      import('./pages/book-now/book-now.module').then(
        (m: any) => m.BookNowPageModule
      ),
  },
  {
    path: 'maintenance-jobs',
    loadChildren: () =>
      import('./pages/maintenance-jobs/maintenance-jobs.module').then(
        (m: any) => m.MaintenanceJobsPageModule
      ),
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./pages/messages/messages.module').then(
        (m: any) => m.MessagesPageModule
      ),
  },
  {
    path: 'report-maintenance',
    loadChildren: () =>
      import('./pages/report-maintenance/report-maintenance.module').then(
        (m: any) => m.ReportMaintenancePageModule
      ),
  },
  {
    path: 'invoices',
    loadChildren: () =>
      import('./pages/invoices/invoices.module').then(
        (m: any) => m.InvoicesPageModule
      ),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
